import React from "React";
import Layout from "../components/Global/Layout";
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Blogs from "../components/Global/Blog";

import "../assets/styles/blogpage.scss"


export const query = graphql`
  query Blog {
    allWpPost(limit: 3) {
        nodes {
          author {
            node {
              id
              firstName
              lastName
            }
          }
          featuredImage {
            node {
              id
              remoteFile {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 300) {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
          id
          title
          uri
          date
          blogPostMeta {
            blogExcerpt
          }
        }
      }
  }
`

export default (data) => {

    const current = "blog"

    return(
        <>
        <Layout current={current}>
        <Helmet>
          <title>Insights - Develescope</title>
        </Helmet>
        <div className="blog-nav">
            <div className="blog-cat">
                <h5>All Categories</h5>
            </div>
            <div className="blog-latest">
                <p>Latest articles</p>
            </div>
        </div>
        <section className="section blog-page">
            <div className="container">
                <div className="top-txt">
                    <h1>Our newest entries</h1>
                </div>
                <div className="columns is-multiline">
                  <Blogs blogs={data.data.allWpPost.nodes}/>
                </div>
            </div>
        </section>
        </Layout>
        </>
    )
}